import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { confirmBookingWithDelay, clearBookingError } from '../../actions/booking';
import { setPaymentProvider } from '../../actions/payment';
import { appendKlarnaScript } from '../../helpers/klarna';
import { getPaymentOptions, getTotalDiscount, PaymentOption } from '../../helpers/payment';
import { formatPhoneNumber } from '../../helpers/phone-number';
import { getMergedWebSettings, getPreference } from '../../helpers/settings';
import { Step, getFirstStep, scrollIntoView } from '../../helpers/nav';
import { web } from '../../helpers/preference-keys';
import VerifyForm from './verify-form';
import Expired from './expired';
import Timer from './timer';
import Summary from '../summary';
import PaymentError from '../payment/payment-error';
import VerifyProgress from './verify-progress';
import VoucherForm from '../payment/voucher-form';

class Verify extends React.Component {
  componentDidMount() {
    const { paymentRequired, paymentAmountIncVat, paymentOptions, customer } = this.props;
    const klarnaEnabled = paymentOptions.includes(PaymentOption.KlarnaPayLater)
      || paymentOptions.includes(PaymentOption.KlarnaPayOverTime);

    if (paymentRequired && paymentAmountIncVat && klarnaEnabled) {
      this.props.appendKlarnaScript();
    }
    if (paymentRequired && paymentAmountIncVat && paymentOptions.length === 1) {
      this.props.setPaymentProvider(paymentOptions[0]);
    }
    if (paymentRequired && !paymentAmountIncVat) {
      this.props.setPaymentProvider('None');
    }
    if (customer) {
      window.addEventListener('beforeunload', this.beforeUnload);
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, paymentAmountIncVat } = this.props;

    if (!prevProps.loading && loading) {
      scrollIntoView();
    }

    if (prevProps.paymentAmountIncVat && !paymentAmountIncVat) {
      this.props.setPaymentProvider('None');
    }
    if (!prevProps.paymentAmountIncVat && paymentAmountIncVat) {
      this.props.setPaymentProvider(null);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  }

  beforeUnload = (ev) => {
    const { intl, postConfirmRedirectUrl, confirmRef } = this.props;
    if (postConfirmRedirectUrl || confirmRef) {
      return true;
    }
    const message = intl.formatMessage({ id: 'verify.beforeUnload' });
    // eslint-disable-next-line no-param-reassign
    (ev || window.event).returnValue = message;
    return message;
  };

  confirmBooking = ({ code, ...paymentData }) => {
    const { confirmationMethodPin } = this.props;
    const pin = confirmationMethodPin ? code : '000000';

    return this.props.confirmBooking(pin, paymentData);
  };

  clearError = () => {
    this.props.clearBookingError();
  };

  render() {
    const {
      services, customer, expired, loading, confirmationMethodPin, paymentOptions, discountVoucher,
      paymentAmountIncVat, paymentRequired, paymentProvider, paymentMethod, paymentRefData, totalDiscount,
      history, error, clearBookingError, paymentTerms, firstStep, webSettings, allowDiscountVoucher, intl
    } = this.props;

    const useSmsVerification = !paymentRequired && confirmationMethodPin;
    const paymentError = error && paymentRequired && !paymentMethod;
    const headerMessage = paymentRequired ? 'payment.heading' : 'verify.heading';
    const multipleOptions = paymentOptions.length > 1;

    const { cancelUpToMinutes } = webSettings;
    const cancellationPeriod = moment.duration(cancelUpToMinutes, 'm').humanize();
    const paymentExtraInfo = intl.messages['payment.extraInfo']
      ? intl.formatMessage({ id: 'payment.extraInfo' }, { cancellationPeriod })
      : null;

    if (!services || services.isEmpty()) {
      return <Redirect to={firstStep} />;
    }
    if (!customer) {
      return <Redirect to={Step.Customer} />;
    }
    if (expired) {
      return <Expired history={history} />;
    }
    if (paymentError) {
      return <PaymentError error={error} onClose={clearBookingError} />;
    }
    if (loading) {
      return (
        <VerifyProgress
          headerMessage={headerMessage}
          paymentProvider={paymentProvider}
          paymentRefData={paymentRefData}
        />
      );
    }

    return (
      <>
        <div className="cb-header">
          <h2><FormattedMessage id={headerMessage} /></h2>
        </div>
        <Summary showCustomer />
        <div className="cb-center">
          {paymentRequired ? (
            <>
              {multipleOptions && (
                <h3><FormattedMessage id="payment.providerHeading" /></h3>
              )}
              {paymentExtraInfo && (
                <p className="cb-payment-info">{paymentExtraInfo}</p>
              )}
              {allowDiscountVoucher && paymentOptions?.length > 0 && (
                <VoucherForm discountVoucher={discountVoucher} totalDiscount={totalDiscount} />
              )}
            </>
          ) : (
            <p>
              {useSmsVerification ? (
                <>
                  <strong>
                    <FormattedMessage
                      id="verify.confirmPinHeading"
                      values={{ phone: formatPhoneNumber(customer.phone) }}
                    />
                  </strong><br /><br />
                  <FormattedMessage id="verify.confirmPinMessage" />
                </>
              ) : (
                <strong>
                  <FormattedMessage id="verify.confirmMessage" />
                </strong>
              )}
            </p>
          )}
          <VerifyForm
            webSettings={webSettings}
            paymentRequired={paymentRequired}
            paymentAmountIncVat={paymentAmountIncVat}
            useSmsVerification={useSmsVerification}
            paymentOptions={paymentOptions}
            setPaymentProvider={this.props.setPaymentProvider}
            paymentProvider={paymentProvider}
            paymentTerms={paymentTerms}
            onSubmit={this.confirmBooking}
            confirmError={error}
            customer={customer}
          /><br />
          <Timer show={!!useSmsVerification} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { booking, payment, settings } = state;
  const attributes = booking.get('attributes');

  return {
    webSettings: getMergedWebSettings(state),
    confirmationMethodPin: booking.get('confirmationMethod') === 'Pin',
    paymentTerms: getPreference(settings, web.paymentTerms),
    paymentOptions: getPaymentOptions(state),
    confirmRef: booking.get('confirmRef'),
    services: booking.get('services'),
    customer: booking.get('customer'),
    expired: booking.get('expired'),
    loading: booking.get('loading'),
    error: booking.get('error'),
    paymentMethod: payment.get('paymentMethod'),
    paymentRequired: payment.get('paymentRequired'),
    paymentAmountIncVat: booking.get('paymentAmountIncVat'),
    allowDiscountVoucher: booking.get('allowDiscountVoucher'),
    paymentProvider: payment.get('paymentProvider'),
    paymentRefData: payment.get('paymentRefData'),
    discountVoucher: payment.get('discountVoucher'),
    totalDiscount: getTotalDiscount(state),
    postConfirmRedirectUrl: attributes && attributes.postConfirmRedirectUrl,
    firstStep: getFirstStep(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmBooking: (pin, paymentData) => {
      return dispatch(confirmBookingWithDelay(pin, paymentData));
    },
    clearBookingError: () => {
      dispatch(clearBookingError());
    },
    setPaymentProvider: (paymentProvider) => {
      dispatch(setPaymentProvider(paymentProvider));
    },
    appendKlarnaScript: () => {
      dispatch(appendKlarnaScript());
    }
  };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Verify));
